.Portfolio {
  &-Body {
    padding-top: 25px;
    padding-bottom: 40px;
    @include mf(sl) {
      padding-top: 0;
      padding-bottom: 100px; }
    @include mf(lg) {
      position: relative; } }
  &-Top {
    margin-bottom: 12px;
    @include mf(sm) {
      display: flex;
      align-items: center; } }
  &-Title {
    margin-bottom: 10px;
    @include mf(sm) {
      margin-bottom: 0; } }
  &-Select {
    width: 160px;
    height: 27px;
    @include mf(sm) {
      margin-left: 35px; } }
  &-Content {
    width: calc(100% + 16px);
    left: -8px;
    position: relative;
    @include mf(sm) {
      width: calc(100% + 40px);
      left: -20px; } }
  &-MoreBtn {
    width: 227px;
    height: 54px;
    margin: 22px auto 0;
    font-weight: 500;
    &-Arrow {
      margin-left: 16px; } }
  &-Egg {
    display: none;
    @include mf(lg) {
      position: absolute;
      display: block; }
    &_top {
      top: -210px;
      right: calc(100% + 177px); }
    &_bot {
      left: calc(100% + 293px);
      bottom: -34px; } } }

.PortfolioGrid {
  &-Item {
    width: 100%;
    height: 300px;
    padding: 8px;
    float: left;
    @include mf(xs) {
      width: 50%; }
    @include mf(sm) {
      width: 33.33%;
      height: 238px;
      padding: 20px; }
    &_width2 {
      width: 100%;
      @include mf(xs) {
        width: 50%;
        height: 600px; }
      @include mf(sm) {
        width: 66.66%; } }
    &_height-big {
      @include mf(sm) {
        height: 360px; } } }
  &-Sizer {
    width: 100%;
    @include mf(xs) {
      width: 50%; }
    @include mf(sm) {
      width: 33.33%; } } }

.PortfolioCard {
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  &-Hover {
    height: 100%;
    padding: 0 20px;
    background-color: rgba(77, 83, 87, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: $transition-base;
    opacity: 0;
    cursor: default; }
  &-Type {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #fce47e;
    text-transform: uppercase;
    text-align: center; }
  &-Title {
    margin-top: 5px;
    font-size: 24px;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center; }
  &-Subtitle {
    margin-top: 5px;
    font-size: 16px;
    color: #ffffff;
    text-align: center; }
  &-Btn {
    width: 163px;
    height: 45px;
    margin-top: 40px;
 }    //box-shadow: 0px 13px 29px 0 rgba(0, 0, 0, 0.2)
  &:hover {
    .PortfolioCard-Hover {
      opacity: 1; } } }
