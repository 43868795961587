.ProcessingPersonalDataText {
  margin-top: 10px;
  font-size: 13px;
  color: #2b2e83;
  text-align: center;
  @include mf(xs) {
    font-size: 14px; }
  &-Link {
    color: #2b2e83;
    text-decoration: underline;
    display: inline;
    &:hover {
      text-decoration: none; } } }
