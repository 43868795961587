.Btn {
  height: 54px;
  border-radius: 27px;
  background: $main-gradient-start;
  background: linear-gradient(to right, $main-gradient-start 0%,$main-gradient-end 100%);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.4px;
  color: #ffffff;
  text-transform: uppercase;
  box-shadow: 0px 13px 29px 0 rgba(96, 145, 248, 0.36);
  transition: $transition-base;
  &_theme_orange {
    background: $accent-gradient-start;
    background: linear-gradient(to right, $accent-gradient-start 0%,$accent-gradient-end 100%);
    box-shadow: 0px 6px 21px 0 rgba(0, 0, 0, 0.19); }
  &:hover {
    box-shadow: none; } }
