.ExampleIntro {
  &-Body {
    padding-top: 25px;
    padding-bottom: 18px;
    @include mf(sm) {
      padding-top: 50px;
      padding-bottom: 33px; } }
  &-Title {
    margin-bottom: 15px;
    @include mf(sm) {
      margin-bottom: 25px; } }
  &-Text {}
  &-Content {
    margin-top: 40px;
    @include mf(sx) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between; }
    @include mf(sm) {
      margin-top: 80px; } }
  &-Left {
    max-width: 310px;
    margin: 0 auto 37px;
    position: relative;
    @include mf(sx) {
      width: 70%;
      max-width: none;
      margin: 0;
      padding-right: 5%; }
    @include mf(sl) {
      width: 65%;
      padding: 0; }
    &-Egg {
      width: 285px;
      position: absolute;
      top: -16px;
      right: -64px;
      z-index: -1;
      @include mf(sm) {
        width: 400px;
        top: -35px;
        right: auto;
        left: 165px; }
      @include mf(sl) {
        width: 585px; } } }
  &-Right {
    width: calc(100% - 60px);
    max-width: 250px;
    margin: 0 auto;
    padding: 25px 40px 55px;
    background-color: #f1f8fe;
    position: relative;
    @include mf(sx) {
      width: 30%;
      max-width: none;
      margin: 0; }
    @include mf(sl) {
      width: 35%;
      max-width: 360px;
      padding: 40px 76px 90px; }
    &-Simg {
      position: absolute;
      z-index: -1;
      &_bashgos {
        width: 298px;
        left: -83px;
        bottom: 30px;
        @include mf(sl) {
          width: 494px;
          left: -137px;
          bottom: 50px; } } } } }

.ExampleIntroDesktop {
  width: 100%;
  max-width: 615px;
  padding: 4% 3% 27%;
  background-image: url(/img/ExampleIntroDesktop.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  &-Img {
    max-width: 100%;
    display: block; } }

.ExampleIntroMobile {
  width: 100%;
  padding: 23% 6% 26%;
  background-image: url(/img/ExampleIntroMobile.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  &-Img {
    max-width: 100%;
    margin-top: 1px;
    display: block; } }
