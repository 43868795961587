.Contact {
  background-color: #f1f8fe;
  overflow: hidden;
  &-Body {
    padding-top: 70px;
    padding-bottom: 50px;
    position: relative;
    @include mf(sx) {
      padding-top: 80px;
      padding-bottom: 100px; } }
  &-Simg {
    width: 350px;
    position: absolute;
    top: 17px;
    right: -80px;
    @include mf(sx) {
      width: auto; }
    @include mf(lg) {
      top: 0;
      right: -147px; } }
  &-Egg {
    position: absolute;
    &_left-bot {
      display: none;
      @include mf(lg) {
        top: calc(100% - 106px);
        right: calc(100% + 177px);
        display: block; } } } }

.ContactCard {
  position: relative;
  z-index: 2;
  &-Top {
    margin-bottom: 30px;
    position: relative;
    @include mf(sx) {
      margin-bottom: 50px; }
    &-Egg {
      width: 377px;
      position: absolute;
      top: -35px;
      left: -40px;
      z-index: -1;
      @include mf(sx) {
        width: auto;
        top: -45px;
        left: -70px; } } }
  &-Title {
    margin-bottom: 15px;
    font-size: 24px;
    @include mf(sx) {
      margin-bottom: 40px;
      font-size: 36px; } }
  &-Info {}
  &-Form {
    padding: 20px 20px 40px;
    background-color: $main-color;
    @include mf(sx) {
      padding: 30px 35px 55px; } } }

.ContactInfo {
  &-Item {
    position: relative;
    margin-bottom: 10px;
    padding-left: 55px;
    font-size: 18px;
    color: inherit;
    text-decoration: none;
    @include mf(sx) {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      font-size: 24px; }
    &:last-child {
      margin-bottom: 0; }
    &-Phone {
      font-size: 24px;
      font-weight: 700;
      color: $main-color;
      text-decoration: none;
      @include mf(sx) {
        font-size: 30px; } } }
  &-IconWrap {
    position: absolute;
    top: -6px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 39px;
    height: 39px;
    background-color: $main-color;
    border-radius: 50%;
    @include mf(sx) {
      top: -3px; } }
  &-Icon {
    fill: #FFFFFF;
    &_phone {
      width: 20px;
      height: 20px; }
    &_mail {
      width: 20px;
      height: 14px; } }
  &-AppLinks {
    display: block;
    padding-top: 10px;
    @include mf(sx) {
      margin-left: 50px;
      padding-top: 0; } } }

.ContactInfo-Item {
  &:nth-of-type(1) {
    .ContactInfo-Icon {
      top: 5px; } } }
.ContactInfo-Item {
  &:nth-of-type(2) {
    .ContactInfo-Icon {
      @include mf(sx) {
        top: 0; } } } }

.ContactAppLinks {
  display: flex;
  &-Item {
    display: block;
    width: 41px;
    height: 41px;
    margin-right: 10px;
    &:last-child {
      margin-right: 0; } }
  img {
    display: block;
    width: 100%;
    height: 100%; } }


.ContactForm {
  position: relative;
  &-Title {
    margin-bottom: 8px;
    font-size: 24px;
    font-weight: 800;
    color: #ffffff;
    line-height: 1;
    @include mf(sx) {
      margin-bottom: 15px;
      font-size: 30px; }
    @include mf(sm) {
      font-size: 36px; }
    br {
      @include mf(sx) {
        display: none; } } }
  &-Subtitle {
    max-width: 270px;
    margin-bottom: 15px;
    font-size: 14px;
    color: #ffffff;
    line-height: 1.2;
    letter-spacing: 0.2px;
    @include mf(sx) {
      max-width: 340px;
      margin-bottom: 28px;
      font-size: 18px;
      letter-spacing: 0.1px; }
    @include mf(sm) {
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 0.3px; } }
  &-LogoBg {
    display: none;
    @include mf(sm) {
      position: absolute;
      left: 0;
      bottom: -25px;
      display: block; } }
  &-SpecImg {
    display: none;
    @include mf(sl) {
      position: absolute;
      top: -110px;
      right: -20px;
      display: block; } }
  &-Form {
    position: relative;
    z-index: 2; } }
