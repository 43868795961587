.PortfolioNew {
  &-Body {
    padding-top: 25px;
    padding-bottom: 40px;
    @include mf(sl) {
      padding-top: 0;
      padding-bottom: 100px; }
    @include mf(lg) {
      position: relative; } }
  &-Top {
    margin-bottom: 20px;
    @include mf(sm) {
      display: flex;
      align-items: center; } }
  &-Title {
    margin-bottom: 10px;
    @include mf(sm) {
      margin-bottom: 0; } }
  &-Select {
    width: 160px;
    height: 27px;
    @include mf(sm) {
      margin-left: 35px; } }
  &-Content {
    width: calc(100% + 16px);
    left: -8px;
    position: relative;
    &:after {
      content: "";
      display: table;
      clear: both; }
    @include mf(sm) {
      width: calc(100% + 40px);
      left: -20px; } }
  &-MoreBtn {
    width: 227px;
    height: 54px;
    margin: 22px auto 0;
    font-weight: 500;
    &-Arrow {
      width: 14px;
      height: 10px;
      margin-left: 16px;
      fill: #FFFFFF; } }
  &-Egg {
    display: none;
    @include mf(lg) {
      position: absolute;
      display: block; }
    &_top {
      top: -210px;
      right: calc(100% + 177px); }
    &_bot {
      left: calc(100% + 293px);
      bottom: -34px; } }
  .PortfolioNewGrid {
    &-Item {
      float: left;
      width: 100%;
      margin-bottom: 20px;
      padding: 8px;
      @include mf(xs) {
        width: 50%; }
      @include mf(sm) {
        margin-bottom: 40px;
        padding: 0 15px; }
      @include mf(sl) {
        margin-bottom: 50px;
        padding: 0 22px; } }
    &-Sizer {
      width: 100%;
      @include mf(xs) {
        width: 50%; } } }
  &-Show {
    display: flex;
    flex-wrap: wrap; }
  .PortfolioNewCard {
    width: 100%;
    height: 100%;
    &:hover {
      .PortfolioNewCard-Link {
        text-decoration: none; } }
    &-PreviewWrap {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 25px 35px;
      background-color: #eef3f7;
      cursor: pointer;
      @include mf(sl) {
        padding: 45px 64px; } }
    &-Preview {
      max-width: 100%;
      display: block; }
    &-Link {
      display: inline-block;
      margin-top: 5px;
      font-size: 14px;
      font-weight: 700;
      color: $main-color;
      text-decoration: underline;
      cursor: pointer;
      @include mf(sl) {
        margin-top: 10px;
        font-size: 18px; } }
    & {}
    & {} } }
