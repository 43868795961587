.IntroServices {
  position: relative;
  z-index: 2;
  &-Body {}
  &-Item {} }

.IntroServicesCard {
  max-width: 320px;
  margin: 0 auto;
  padding: 25px 17px 40px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 13px 29px 0 rgba(119, 172, 248, 0.17);
  transform: translateY(-20px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include mf(xs) {
    max-width: none;
    padding-left: 35px;
    padding-right: 0; }
  @include mf(sm) {
    padding: 25px 0 40px 20px; }
  @include mf(sl) {
    transform: translateY(-50%);
    padding: 40px 0 50px 50px; }
  &-Item {
    width: 50%;
    margin-top: 30px;
    @include mf(xs) {
      width: 26%; }
    @include mf(sm) {
      width: calc(100% / 6);
      margin-top: 0 !important; }
    &:nth-of-type(1),
    &:nth-of-type(2) {
      margin-top: 0; }
    &:nth-of-type(3) {
      @include mf(xs) {
        margin-top: 0; } }
    &:nth-of-type(2n) {
      width: 40%;
      @include mf(xs) {
        width: 26%; }
      @include mf(sm) {
        width: calc(100% / 6); } } }
  &-Icon {
    max-width: 27px;
    height: 26px !important;
    margin-bottom: 15px;
    fill: $accent-color; }
  &-Text {
    font-size: 14px;
    @include mf(sl) {
      font-size: 16px; } } }
