.About {
  &-Body {
    padding-top: 30px;
    padding-bottom: 17px;
    position: relative;
    @include mf(sl) {
      padding-top: 60px;
      padding-bottom: 50px; } }
  &-Content {
    position: relative;
    z-index: 3;
    @include mf(sm) {
      width: 70%;
      max-width: 870px; }
    @include mf(lg) {
      width: 870px; } }
  &-Title {
    margin-bottom: 10px; }
  &-Logo {
    max-width: 100%;
    margin-bottom: 15px;
    @include mf(sl) {
      margin-bottom: 30px; } }
  &-Description {
    margin-bottom: 35px;
    text-shadow: 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff;
    @include mf(sm) {
      max-width: 565px;
      margin-bottom: 50px; } }
  &-Info {
    width: 50%;
    @include mf(sm) {
      width: 100%; } }
  &-Director {} }

.AboutInfo {
  @include mf(sm) {
    display: flex; }
  &-Item {
    margin-bottom: 40px;
    @include mf(sm) {
      width: 33.33%;
      margin: 0; }
    &:last-of-type {
      margin-bottom: 0; } }
  &-Num {
    margin-bottom: 5px;
    font-size: 60px;
    font-weight: 800;
    color: $accent-color;
    line-height: 0.8;
    @include mf(sl) {
      margin-bottom: 0; } }
  &-Text {
    line-height: 1.1;
    @include mf(sl) {
      line-height: 1.3; }
    br {
      display: none;
      @include mf(xs) {
        display: block; } } } }

.AboutDirector {
  position: absolute;
  right: 0;
  bottom: 59px;
  z-index: 2;
  @include mf(lg) {
    right: 20px;
    bottom: 90px; }
  &-Photo {
    position: absolute;
    &_phone {
      right: -135px;
      bottom: -90px;
      z-index: -1;
      width: 391px;
      @include mf(sl) {
        display: none; } }
    &_desktop {
      display: none;
      @include mf(sl) {
        right: -93px;
        bottom: -63px;
        display: block;
        width: 577px; } } }
  &-Info {
    font-size: 14px;
    color: #ffffff;
    text-align: right;
    position: relative;
    z-index: 2;
    //text-shadow: 0 0 3px #000, 0 0 3px #6091f8, 0 0 3px #6091f8
    //position: relative
    //&:before
    //  content: ''
    //  width: 56px
    //  height: 56px
    //  background-color: $main-color
    //  border-radius: 50%
    //  position: absolute
    //  top: -10px
    //  right: -20px
    //  z-index: -1
    @include mf(sl) {
      font-size: 18px; }
    //  +mf(lg)
    //    width: 67px
 } }    //    height: 67px
