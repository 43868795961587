.ExampleBRoll {
  &-Body {
    padding-top: 20px;
    padding-bottom: 40px;
    @include mf(lg) {
      padding-top: 45px;
      padding-bottom: 80px;
      position: relative; } }
  &-Content {
    display: flex;
    justify-content: space-between; }
  &-Item {
    width: calc(33.33% - 6px * 2/3);
    @include mf(sl) {
      width: calc(33.33% - 17px * 2/3); } }
  &-Btn {
    max-width: 230px;
    margin: 30px auto 0;
    @include mf(sl) {
      margin-top: 70px; } }
  &-Egg {
    display: none;
    @include mf(lg) {
      position: absolute;
      display: block; }
    &_right {
      @include mf(lg) {
        top: 184px;
        left: calc(100% + 310px); } } } }

.ExampleBRollCard {
  background-color: #fff;
  box-shadow: 0px 13px 101px 0 rgba(104, 153, 248, 0.36);
  &-Img {
    max-width: 100%;
    display: block; } }
