.Input {
  display: flex;
  flex-direction: column;
  position: relative;
  &-Control {
    height: 54px;
    padding-left: 30px;
    background-color: #f6f6f6;
    font-size: 14px;
    border: none;
    border-radius: 27px; }
  &-RubWrap {
    position: absolute;
    top: 50%;
    right: 20px;
    font-size: 14px;
    color: #99a0a6;
    transform: translateY(-50%); } }
