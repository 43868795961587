.Feedback {
  overflow: hidden;
  &-Body {
    padding-top: 17px;
    padding-bottom: 40px;
    position: relative;
    @include mf(lg) {
      padding-top: 30px;
      padding-bottom: 60px; } }
  &-Card {
    position: relative;
    z-index: 2; }
  &-Egg {
    position: absolute;
    &_bot {
      width: 595px;
      left: calc(100% - 220px);
      bottom: -505px;
      transform: rotate(-30deg);
      @include mf(lg) {
        bottom: auto;
        top: calc(100% - 205px);
        left: calc(100% - 383px); } } } }

.FeedbackCard {
  padding: 25px 15px 20px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 13px 76px 0 rgba(119, 172, 248, 0.19);
  @include mf(sl) {
    padding: 45px 70px 55px 60px; }
  &-Title {
    margin-bottom: 25px;
    font-size: 24px;
    font-weight: 900;
    text-align: center;
    @include mf(sm) {
      font-size: 36px;
      text-align: left; }
    @include mf(lg) {
      margin-bottom: 40px; } }
  &-Slider {} }

.FeedbackSlider {
  padding-bottom: 80px;
  position: relative;
  @include mf(lg) {
    padding-bottom: 130px; }
  &-Slide {
    @include mf(sm) {
      display: flex !important; } }
  &-Arrow {
    width: 48px;
    height: 48px;
    background-color: #78aef8;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    transition: $transition-base;
    cursor: pointer;
    @include mf(sm) {
      width: 62px;
      height: 62px; }
    &:hover {
      background-color: #6091f8; }
    &-Img {
      display: block;
      width: 12px;
      height: 20px;
      fill: #FFFFFF; }
    &_prev {
      right: calc(50% + 4px);
      @include mf(sm) {
        right: 73px; } }
    &_next {
      left: calc(50% + 4px);
      transform: rotate(-180deg);
      @include mf(sm) {
        left: auto;
        right: 0; } } } }

.FeedbackSlide {
  &-AvatarWrap {
    width: 134px;
    height: 134px;
    margin: 0 auto 15px;
    background-color: #f1f8fe;
    border-radius: 50%;
    overflow: hidden; }
  &-Avatar {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    display: block; }
  &-Content {
    @include mf(sm) {
      width: calc(100% - 134px);
      padding-left: 34px; } }
  &-Name {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 700;
    color: $main-color;
    text-align: center;
    @include mf(sm) {
      margin-bottom: 0;
      font-size: 18px;
      text-align: left; } }
  &-Company {
    margin-bottom: 15px;
    font-size: 14px;
    text-align: center;
    @include mf(sm) {
      margin-bottom: 25px;
      font-size: 18px;
      text-align: left; }
    br {
      @include mf(sm) {
        display: none; } } }
  &-Text {
    padding: 0 30px;
    font-size: 16px;
    text-align: justify;
    position: relative;
    @include mf(sm) {
      padding: 0 25px;
      font-size: 18px;
      text-align: left; }
    &:before,
    &:after {
      content: '“';
      font-size: 72px;
      font-weight: 100;
      color: $main-color;
      line-height: 0.8;
      position: absolute; }
    &:before {
      top: 0;
      left: -0; }
    &:after {
      content: '”';
      line-height: 0;
      right: 0;
      bottom: -10px;
      @include mf(sm) {
        display: inline-block;
        position: relative;
        bottom: -33px; } } } }
