.Phone {
  &-Only {
    display: inherit !important;
    @include mf(xs) {
      display: none !important; } }
  &-Hidden {
    display: none !important;
    @include mf(xs) {
      display: inherit !important; } } }

@import "../../common.blocks/Btn/Btn.sass";
@import "../../common.blocks/Btn/_disable/Btn_disable.sass";

@import "../../common.blocks/Select/Select.sass";
@import "../../common.blocks/Select/_theme_arrow/Select_theme_arrow.sass";

@import "../../common.blocks/Label/Label.sass";

@import "../../common.blocks/Input/Input.sass";
@import "../../common.blocks/Input/_error/Input_error.sass";
@import "../../common.blocks/Input/_bgWhite/Input_bgWhite.sass";

@import "../../common.blocks/Form/Form.sass";

@import "../../common.blocks/ProcessingPersonalDataText/ProcessingPersonalDataText.sass";

@import "../../common.blocks/Logo/Logo.sass";


@import "../../project.blocks/Btn/index.sass";
@import "../../project.blocks/Form/_theme_line/index.sass";
@import "../../project.blocks/Heading/index.sass";
@import "../../project.blocks/Select/_theme_grey/index.sass";

