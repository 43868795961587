.Intro {
  background-color: #f1f8fe;
  &-Body {
    padding-top: 99px;
    padding-bottom: 50px;
    position: relative;
    @include mf(sl) {
      padding-top: 190px;
      padding-bottom: 180px; } }
  &-Content {
    position: relative;
    z-index: 2; }
  &-Title {
    font-size: 30px;
    font-weight: 900;
    line-height: 0.8;
    text-transform: uppercase;
    text-shadow: 0 0 3px #f1f8fe, 0 0 3px #f1f8fe, 0 0 3px #f1f8fe, 0 0 3px #f1f8fe, 0 0 3px #f1f8fe;
    @include mf(sm) {
      font-size: 48px;
      line-height: 1; }
    &_font {
      font-size: 24px;
      @include mf(sm) {
        font-size: 48px; } }
    &_grey {
      color: #98a1a8;
      white-space: nowrap; }
    &_case {
      text-transform: initial;
      line-height: 1.7;
      @include mf(sm) {
        line-height: 1; } } }
  &-Subtitle {
    max-width: 555px;
    margin-top: 5px;
    font-size: 16px;
    font-weight: 500;
    color: #42484c;
    text-shadow: 0 0 3px #f1f8fe, 0 0 3px #f1f8fe, 0 0 3px #f1f8fe, 0 0 3px #f1f8fe, 0 0 3px #f1f8fe;
    @include mf(sm) {
      margin-top: 40px;
      font-size: 20px; } }
  &-Form {
    @include mf(sm) {
      margin-top: 50px; } }
  &-Egg {
    margin-top: 10px;
    margin-bottom: 15px;
    @include mf(sm) {
      margin: 0; } } }

.IntroEgg {
  position: relative;
  z-index: -1;
  @include mf(sm) {
    position: absolute;
    top: 0;
    right: 0; }
  &-MainImg {
    width: 230px;
    @include mf(sl) {
      width: auto; } }
  &-PhotoImg {
    width: 286px;
    position: absolute;
    top: 10px;
    left: -10px;
    @include mf(sl) {
      width: auto;
      max-width: 600px;
      top: -25px;
      left: -25px; } }
  &-GradientImg {
    width: 800px;
    position: absolute;
    top: -125px;
    left: 145px;
    z-index: -1;
    @include mf(sm) {
      top: -180px;
      left: 45px; }
    @include mf(sl) {
      width: auto;
      top: -450px;
      left: 20px; } } }


.Intro_header {
  overflow: hidden;
  .Intro {
    &-Body {
      height: 70px;
      padding: 0;
      @include mf(lg) {
        height: 120px; } } }
  .IntroEgg {
    display: none;
    @include mf(sm) {
      top: 90px;
      z-index: 0;
      display: block; }
    @include mf(sl) {
      top: 160px; } } }
