.Offer {
  background-color: #f1f8fe;
  overflow: hidden;
  &-Container {}
  &-Body {
    padding-top: 30px;
    position: relative;
    @include mf(sm) {
      padding-bottom: 80px; }
    @include mf(sl) {
      padding-top: 70px;
      padding-bottom: 100px; } }
  &-Content {
    position: relative;
    z-index: 2;
    @include mf(sm) {
      display: flex;
      align-items: flex-end; } }
  &-Left {
    margin-bottom: 30px;
    @include mf(sm) {
      width: 65%;
      max-width: 520px;
      margin-bottom: 0; }
    @include mf(sl) {
      width: 520px; } }
  &-Title {
    margin-bottom: 30px;
    font-size: 34px;
    font-weight: 900;
    line-height: 1;
    @include mf(sl) {
      font-size: 48px; }
    @include mf(lg) {
      margin-bottom: 30px; }
    span {} }
  &-Text {
    margin-bottom: 25px;
    font-size: 16px;
    color: #9da5aa;
    line-height: 1.5;
    @include mf(sl) {
      font-size: 18px; }
    @include mf(lg) {
      margin-bottom: 35px; }
    span {
      padding: 0 4px;
      background-color: #ffffff; } }
  &-Form {}
  &-Right {
    @include mf(sm) {
      width: 35%;
      margin-bottom: -80px; }
    @include mf(sl) {
      width: calc(100% - 520px);
      margin-bottom: -100px; } }
  &-ImgHand {
    max-width: calc(100% + 50px);
    display: block;
    position: relative;
    left: -25px;
    @include mf(sm) {
      max-width: 100%;
      left: auto; }
    @include mf(lg) {
      width: 724px;
      max-width: none; } }
  &-Egg {
    position: absolute;
    &_left {
      top: calc(100% - 225px);
      right: calc(100% - 162px);
      @include mf(sm) {
        top: calc(100% - 120px); }
      @include mf(lg) {
        top: calc(100% - 250px);
        right: calc(100% + 150px); } }
    &_right {
      left: calc(100% - 76px);
      bottom: calc(100% - 311px);
      @include mf(lg) {
        left: calc(100% - 250px);
        bottom: 15px; } } } }
