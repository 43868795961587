//@import "../../../node_modules/bootstrap/scss/bootstrap-grid"
@import "bourbon";
@import "vars";

@include font-face("ProximaNova", "/font/ProximaNovaLight/ProximaNovaLight", 100, $file-formats: woff);
@include font-face("ProximaNova", "/font/ProximaNovaRegular/ProximaNovaRegular", 400, $file-formats: woff);
@include font-face("ProximaNova", "/font/ProximaNovaSemibold/ProximaNovaSemibold", 500, $file-formats: woff);
@include font-face("ProximaNova", "/font/ProximaNovaBold/ProximaNovaBold", 700, $file-formats: woff);
@include font-face("ProximaNova", "/font/ProximaNovaExtrabold/ProximaNovaExtrabold", 800, $file-formats: woff);
@include font-face("ProximaNova", "/font/ProximaNovaBlack/ProximaNovaBlack", 900, $file-formats: woff);

body {
  margin: 0;
  padding: 0;
  font-family: $font-default;
  font-size: 14px;
  font-weight: 400;
  color: #4d5357;
  @include mf(sm) {
    font-size: 18px; } }

* {
  outline: none; }

.fonts-stage-1 body {
  font-family: $webfont-default;
  line-height: 1.3; }

.fonts-stage-2 body {
  font-family: $webfont-default;
  line-height: 1.3; }

.Container {
  width: 100%;
  max-width: 1150px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  &_lines {
    background-position: center top;
    background-repeat: repeat-y;
    -webkit-background-size: 100% auto;
    background-size: 100% auto;
    &_light {
      background-image: url(/img/Container-Lines_light.png); }
    &_dark {
      background-image: url(/img/Container-Lines_dark.png); } } }

b {
  font-weight: 700; }

span {
  font-weight: inherit; }

.Color_blue {
  color: $main-color; }
.Color_orange {
  color: $accent-color; }

.Bg_gradient {
  &_blue {
    background: $main-gradient-start;
    background: linear-gradient(to right, $main-gradient-start 0%,$main-gradient-end 100%); }
  &_orange {
    background: $accent-gradient-start;
    background: linear-gradient(to right, $accent-gradient-start 0%,$accent-gradient-end 100%); } }

.PortfolioPage {
  .Portfolio {
    &-Body {
      padding-top: 20px;
      padding-bottom: 38px;
      @include mf(sm) {
        padding-top: 45px;
        padding-bottom: 55px; } } } }

.fancybox-content {
  max-height: 95%;
  padding: 15px; }
