@charset "UTF-8";
/*
	HTML5 Reset :: style.css
	----------------------------------------------------------
	We have learned much from/been inspired by/taken code where offered from:

	Eric Meyer					:: https://meyerweb.com
	HTML5 Doctor				:: https://html5doctor.com
	and the HTML5 Boilerplate	:: https://html5boilerplate.com

-------------------------------------------------------------------------------*/
/* Let's default this puppy out
-------------------------------------------------------------------------------*/
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent; }

article, aside, figure, footer, header, nav, section, details, summary {
  display: block; }

/* Handle box-sizing while better addressing child elements:
   https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

/* consider resetting the default cursor: https://gist.github.com/murtaugh/5247154 */
/*!* Responsive images and other embedded objects
*!
img,
object,
embed {max-width: 100%;}*/
/*
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
	 In fact, it *will* cause problems with Google Maps' controls at small size.
	If this is the case for you, try uncommenting the following:

#map img {
		max-width: none;
}
*/
/* force a vertical scrollbar to prevent a jumpy page */
html {
  overflow-y: scroll; }

/* we use a lot of ULs that aren't bulleted.
	don't forget to restore the bullets within content. */
ul, ol {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0; }

th {
  font-weight: bold;
  vertical-align: bottom; }

td {
  font-weight: normal;
  vertical-align: top; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

pre {
  white-space: pre;
  /* CSS2 */
  white-space: pre-wrap;
  /* CSS 2.1 */
  white-space: pre-line;
  /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word;
  /* IE */ }

input[type="radio"] {
  vertical-align: text-bottom; }

input[type="checkbox"] {
  vertical-align: bottom; }

.ie7 input[type="checkbox"] {
  vertical-align: baseline; }

.ie6 input {
  vertical-align: text-bottom; }

select, input, textarea {
  font: 99% sans-serif; }

table {
  font-size: inherit;
  font: 100%; }

small {
  font-size: 85%; }

strong {
  font-weight: bold; }

td, td img {
  vertical-align: top; }

/* Make sure sup and sub don't mess with your line-heights https://gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* standardize any monospaced elements */
pre, code, kbd, samp {
  font-family: monospace, sans-serif; }

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {
  cursor: pointer; }

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {
  margin: 0; }

/* make buttons play nice in IE */
button,
input[type=button] {
  width: auto;
  overflow: visible; }

/* scale images in IE7 more attractively */
.ie7 img {
  -ms-interpolation-mode: bicubic; }

/* prevent BG image flicker upon hover
   (commented out as usage is rare, and the filter syntax messes with some pre-processors)
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}
*/
/* let's clear some floats */
.clearfix:before, .clearfix:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden; }

.clearfix:after {
  clear: both; }

.clearfix {
  zoom: 1; }

@font-face {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 100;
  src: url("/font/ProximaNovaLight/ProximaNovaLight.woff") format("woff"); }

@font-face {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 400;
  src: url("/font/ProximaNovaRegular/ProximaNovaRegular.woff") format("woff"); }

@font-face {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 500;
  src: url("/font/ProximaNovaSemibold/ProximaNovaSemibold.woff") format("woff"); }

@font-face {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 700;
  src: url("/font/ProximaNovaBold/ProximaNovaBold.woff") format("woff"); }

@font-face {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 800;
  src: url("/font/ProximaNovaExtrabold/ProximaNovaExtrabold.woff") format("woff"); }

@font-face {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 900;
  src: url("/font/ProximaNovaBlack/ProximaNovaBlack.woff") format("woff"); }

body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #4d5357; }
  @media (min-width: 768px) {
    body {
      font-size: 18px; } }

* {
  outline: none; }

.fonts-stage-1 body {
  font-family: "ProximaNova", sans-serif;
  line-height: 1.3; }

.fonts-stage-2 body {
  font-family: "ProximaNova", sans-serif;
  line-height: 1.3; }

.Container {
  width: 100%;
  max-width: 1150px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box; }
  .Container_lines {
    background-position: center top;
    background-repeat: repeat-y;
    -webkit-background-size: 100% auto;
    background-size: 100% auto; }
    .Container_lines_light {
      background-image: url(/img/Container-Lines_light.png); }
    .Container_lines_dark {
      background-image: url(/img/Container-Lines_dark.png); }

b {
  font-weight: 700; }

span {
  font-weight: inherit; }

.Color_blue {
  color: #6091f8; }

.Color_orange {
  color: #fcb364; }

.Bg_gradient_blue {
  background: #6091f8;
  background: linear-gradient(to right, #6091f8 0%, #78aef8 100%); }

.Bg_gradient_orange {
  background: #fcb364;
  background: linear-gradient(to right, #fcb364 0%, #fce57f 100%); }

.PortfolioPage .Portfolio-Body {
  padding-top: 20px;
  padding-bottom: 38px; }
  @media (min-width: 768px) {
    .PortfolioPage .Portfolio-Body {
      padding-top: 45px;
      padding-bottom: 55px; } }

.fancybox-content {
  max-height: 95%;
  padding: 15px; }

.Phone-Only {
  display: inherit !important; }
  @media (min-width: 480px) {
    .Phone-Only {
      display: none !important; } }

.Phone-Hidden {
  display: none !important; }
  @media (min-width: 480px) {
    .Phone-Hidden {
      display: inherit !important; } }

.Btn {
  font-size: 100%;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center; }

button.Btn {
  display: block;
  text-align: center; }

.Btn_disable {
  background-color: #C2C2C2;
  cursor: not-allowed; }

.Select {
  padding-left: 15px;
  background-color: #fff; }

.Select_theme_arrow {
  padding-right: 35px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(/img/SelectSrrow.png);
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat; }

.Label {
  display: inline-block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #4d5357; }
  @media (min-width: 768px) {
    .Label {
      font-size: 18px; } }

.Input {
  display: flex;
  flex-direction: column;
  position: relative; }
  .Input-Control {
    height: 54px;
    padding-left: 30px;
    background-color: #f6f6f6;
    font-size: 14px;
    border: none;
    border-radius: 27px; }
  .Input-RubWrap {
    position: absolute;
    top: 50%;
    right: 20px;
    font-size: 14px;
    color: #99a0a6;
    transform: translateY(-50%); }

.Input_error {
  background-color: #fed1dd !important; }

.Input_bgWhite .Input-Control {
  background-color: #ffffff; }

.Form-Row {
  margin-bottom: 10px; }
  .Form-Row:last-child {
    margin-bottom: 0; }

.Form-Btn {
  width: 100%;
  margin-top: 30px; }

.ProcessingPersonalDataText {
  margin-top: 10px;
  font-size: 13px;
  color: #2b2e83;
  text-align: center; }
  @media (min-width: 480px) {
    .ProcessingPersonalDataText {
      font-size: 14px; } }
  .ProcessingPersonalDataText-Link {
    color: #2b2e83;
    text-decoration: underline;
    display: inline; }
    .ProcessingPersonalDataText-Link:hover {
      text-decoration: none; }

.Logo-Image {
  max-width: 100%;
  display: block; }

.Btn {
  height: 54px;
  border-radius: 27px;
  background: #6091f8;
  background: linear-gradient(to right, #6091f8 0%, #78aef8 100%);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.4px;
  color: #ffffff;
  text-transform: uppercase;
  box-shadow: 0px 13px 29px 0 rgba(96, 145, 248, 0.36);
  transition: all ease-in-out 0.25s; }
  .Btn_theme_orange {
    background: #fcb364;
    background: linear-gradient(to right, #fcb364 0%, #fce57f 100%);
    box-shadow: 0px 6px 21px 0 rgba(0, 0, 0, 0.19); }
  .Btn:hover {
    box-shadow: none; }

.Form_theme_line {
  max-width: 320px; }
  @media (min-width: 480px) {
    .Form_theme_line {
      max-width: 585px;
      display: flex; } }
  .Form_theme_line .Input {
    width: 100%; }
    @media (min-width: 480px) {
      .Form_theme_line .Input {
        width: calc(100% - 200px); } }
  .Form_theme_line .Btn {
    width: 100%;
    margin-top: 10px;
    z-index: 1; }
    @media (min-width: 480px) {
      .Form_theme_line .Btn {
        width: 227px;
        margin-top: 0;
        margin-left: -40px; } }

.Heading_lvl2 {
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase; }
  @media (min-width: 480px) {
    .Heading_lvl2 {
      font-size: 36px; } }

.Select_theme_grey {
  background-color: #e4ebf2;
  font-size: 10px;
  font-weight: 700;
  color: #98a1a8;
  border: none;
  border-radius: 13.5px;
  text-transform: uppercase; }

.Header {
  width: 100%;
  padding: 11px 0;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.18); }
  @media (min-width: 768px) {
    .Header {
      padding: 0;
      background-color: transparent;
      position: absolute;
      box-shadow: none; } }
  .Header .Container {
    padding: 0; }
    @media (min-width: 768px) {
      .Header .Container {
        padding: 11px 15px 9px; } }
    @media (min-width: 1200px) {
      .Header .Container {
        padding-top: 34px; } }
  .Header-Body {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .Header-Logo {
    width: 157px; }
  .Header-Left {
    display: flex;
    align-items: center; }
  .Header-Right {
    display: flex;
    align-items: center; }
  .Header-Nav {
    display: none; }
    @media (min-width: 768px) {
      .Header-Nav {
        margin-left: 30px;
        display: flex; } }
  .Header-CallBackBtn {
    display: none; }
    @media (min-width: 768px) {
      .Header-CallBackBtn {
        width: 166px;
        height: 39px;
        font-size: 12px;
        border-radius: 19.5px;
        display: flex; } }

.HeaderInfo {
  display: flex;
  flex-direction: column; }
  .HeaderInfo-Text {
    display: none; }
    @media (min-width: 480px) {
      .HeaderInfo-Text {
        display: initial; } }

.HeaderPhone {
  width: 49px;
  height: 45px;
  padding: 16px 14px;
  background: #6091f8;
  background: linear-gradient(to right, #6091f8 0%, #78aef8 100%);
  border-radius: 10px 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (min-width: 768px) {
    .HeaderPhone {
      width: initial;
      height: initial;
      padding: initial;
      color: #000;
      background: initial;
      border: none;
      text-decoration: none;
      display: initial; } }
  @media (min-width: 992px) {
    .HeaderPhone {
      margin-left: 25px;
      display: flex; } }
  .HeaderPhone-Icon {
    display: none; }
    @media (min-width: 992px) {
      .HeaderPhone-Icon {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        fill: #FFFFFF;
        display: block; } }
  .HeaderPhone-Number {
    display: none; }
    @media (min-width: 992px) {
      .HeaderPhone-Number {
        font-size: 18px;
        font-weight: 300;
        color: #ffffff;
        display: initial; } }
  .HeaderPhone-Img {
    width: 32px;
    display: block; }
    @media (min-width: 768px) {
      .HeaderPhone-Img {
        display: none; } }

.HeaderNav {
  display: none; }
  @media (min-width: 768px) {
    .HeaderNav {
      display: flex;
      align-items: center; } }
  .HeaderNav-Link {
    margin-right: 20px;
    font-size: 14px;
    font-weight: 800;
    color: #b0b9c0;
    text-decoration: none;
    text-transform: uppercase;
    transition: all ease-in-out 0.25s; }
    .HeaderNav-Link:last-of-type {
      margin-right: 0; }
    .HeaderNav-Link:hover, .HeaderNav-Link.active {
      color: #4d5357; }

.DefmenuBtn {
  background: #6091f8;
  background: linear-gradient(to right, #6091f8 0%, #78aef8 100%);
  display: flex; }
  @media (min-width: 768px) {
    .DefmenuBtn {
      display: none; } }

.StandardWrap-Body {
  padding-top: 40px;
  padding-bottom: 50px; }

.StandardWrap-Title {
  margin-bottom: 20px; }

.Intro {
  background-color: #f1f8fe; }
  .Intro-Body {
    padding-top: 99px;
    padding-bottom: 50px;
    position: relative; }
    @media (min-width: 992px) {
      .Intro-Body {
        padding-top: 190px;
        padding-bottom: 180px; } }
  .Intro-Content {
    position: relative;
    z-index: 2; }
  .Intro-Title {
    font-size: 30px;
    font-weight: 900;
    line-height: 0.8;
    text-transform: uppercase;
    text-shadow: 0 0 3px #f1f8fe, 0 0 3px #f1f8fe, 0 0 3px #f1f8fe, 0 0 3px #f1f8fe, 0 0 3px #f1f8fe; }
    @media (min-width: 768px) {
      .Intro-Title {
        font-size: 48px;
        line-height: 1; } }
    .Intro-Title_font {
      font-size: 24px; }
      @media (min-width: 768px) {
        .Intro-Title_font {
          font-size: 48px; } }
    .Intro-Title_grey {
      color: #98a1a8;
      white-space: nowrap; }
    .Intro-Title_case {
      text-transform: initial;
      line-height: 1.7; }
      @media (min-width: 768px) {
        .Intro-Title_case {
          line-height: 1; } }
  .Intro-Subtitle {
    max-width: 555px;
    margin-top: 5px;
    font-size: 16px;
    font-weight: 500;
    color: #42484c;
    text-shadow: 0 0 3px #f1f8fe, 0 0 3px #f1f8fe, 0 0 3px #f1f8fe, 0 0 3px #f1f8fe, 0 0 3px #f1f8fe; }
    @media (min-width: 768px) {
      .Intro-Subtitle {
        margin-top: 40px;
        font-size: 20px; } }
  @media (min-width: 768px) {
    .Intro-Form {
      margin-top: 50px; } }
  .Intro-Egg {
    margin-top: 10px;
    margin-bottom: 15px; }
    @media (min-width: 768px) {
      .Intro-Egg {
        margin: 0; } }

.IntroEgg {
  position: relative;
  z-index: -1; }
  @media (min-width: 768px) {
    .IntroEgg {
      position: absolute;
      top: 0;
      right: 0; } }
  .IntroEgg-MainImg {
    width: 230px; }
    @media (min-width: 992px) {
      .IntroEgg-MainImg {
        width: auto; } }
  .IntroEgg-PhotoImg {
    width: 286px;
    position: absolute;
    top: 10px;
    left: -10px; }
    @media (min-width: 992px) {
      .IntroEgg-PhotoImg {
        width: auto;
        max-width: 600px;
        top: -25px;
        left: -25px; } }
  .IntroEgg-GradientImg {
    width: 800px;
    position: absolute;
    top: -125px;
    left: 145px;
    z-index: -1; }
    @media (min-width: 768px) {
      .IntroEgg-GradientImg {
        top: -180px;
        left: 45px; } }
    @media (min-width: 992px) {
      .IntroEgg-GradientImg {
        width: auto;
        top: -450px;
        left: 20px; } }

.Intro_header {
  overflow: hidden; }
  .Intro_header .Intro-Body {
    height: 70px;
    padding: 0; }
    @media (min-width: 1200px) {
      .Intro_header .Intro-Body {
        height: 120px; } }
  .Intro_header .IntroEgg {
    display: none; }
    @media (min-width: 768px) {
      .Intro_header .IntroEgg {
        top: 90px;
        z-index: 0;
        display: block; } }
    @media (min-width: 992px) {
      .Intro_header .IntroEgg {
        top: 160px; } }

.IntroServices {
  position: relative;
  z-index: 2; }

.IntroServicesCard {
  max-width: 320px;
  margin: 0 auto;
  padding: 25px 17px 40px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 13px 29px 0 rgba(119, 172, 248, 0.17);
  transform: translateY(-20px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  @media (min-width: 480px) {
    .IntroServicesCard {
      max-width: none;
      padding-left: 35px;
      padding-right: 0; } }
  @media (min-width: 768px) {
    .IntroServicesCard {
      padding: 25px 0 40px 20px; } }
  @media (min-width: 992px) {
    .IntroServicesCard {
      transform: translateY(-50%);
      padding: 40px 0 50px 50px; } }
  .IntroServicesCard-Item {
    width: 50%;
    margin-top: 30px; }
    @media (min-width: 480px) {
      .IntroServicesCard-Item {
        width: 26%; } }
    @media (min-width: 768px) {
      .IntroServicesCard-Item {
        width: calc(100% / 6);
        margin-top: 0 !important; } }
    .IntroServicesCard-Item:nth-of-type(1), .IntroServicesCard-Item:nth-of-type(2) {
      margin-top: 0; }
    @media (min-width: 480px) {
      .IntroServicesCard-Item:nth-of-type(3) {
        margin-top: 0; } }
    .IntroServicesCard-Item:nth-of-type(2n) {
      width: 40%; }
      @media (min-width: 480px) {
        .IntroServicesCard-Item:nth-of-type(2n) {
          width: 26%; } }
      @media (min-width: 768px) {
        .IntroServicesCard-Item:nth-of-type(2n) {
          width: calc(100% / 6); } }
  .IntroServicesCard-Icon {
    max-width: 27px;
    height: 26px !important;
    margin-bottom: 15px;
    fill: #fcb364; }
  .IntroServicesCard-Text {
    font-size: 14px; }
    @media (min-width: 992px) {
      .IntroServicesCard-Text {
        font-size: 16px; } }

.Portfolio-Body {
  padding-top: 25px;
  padding-bottom: 40px; }
  @media (min-width: 992px) {
    .Portfolio-Body {
      padding-top: 0;
      padding-bottom: 100px; } }
  @media (min-width: 1200px) {
    .Portfolio-Body {
      position: relative; } }

.Portfolio-Top {
  margin-bottom: 12px; }
  @media (min-width: 768px) {
    .Portfolio-Top {
      display: flex;
      align-items: center; } }

.Portfolio-Title {
  margin-bottom: 10px; }
  @media (min-width: 768px) {
    .Portfolio-Title {
      margin-bottom: 0; } }

.Portfolio-Select {
  width: 160px;
  height: 27px; }
  @media (min-width: 768px) {
    .Portfolio-Select {
      margin-left: 35px; } }

.Portfolio-Content {
  width: calc(100% + 16px);
  left: -8px;
  position: relative; }
  @media (min-width: 768px) {
    .Portfolio-Content {
      width: calc(100% + 40px);
      left: -20px; } }

.Portfolio-MoreBtn {
  width: 227px;
  height: 54px;
  margin: 22px auto 0;
  font-weight: 500; }
  .Portfolio-MoreBtn-Arrow {
    margin-left: 16px; }

.Portfolio-Egg {
  display: none; }
  @media (min-width: 1200px) {
    .Portfolio-Egg {
      position: absolute;
      display: block; } }
  .Portfolio-Egg_top {
    top: -210px;
    right: calc(100% + 177px); }
  .Portfolio-Egg_bot {
    left: calc(100% + 293px);
    bottom: -34px; }

.PortfolioGrid-Item {
  width: 100%;
  height: 300px;
  padding: 8px;
  float: left; }
  @media (min-width: 480px) {
    .PortfolioGrid-Item {
      width: 50%; } }
  @media (min-width: 768px) {
    .PortfolioGrid-Item {
      width: 33.33%;
      height: 238px;
      padding: 20px; } }
  .PortfolioGrid-Item_width2 {
    width: 100%; }
    @media (min-width: 480px) {
      .PortfolioGrid-Item_width2 {
        width: 50%;
        height: 600px; } }
    @media (min-width: 768px) {
      .PortfolioGrid-Item_width2 {
        width: 66.66%; } }
  @media (min-width: 768px) {
    .PortfolioGrid-Item_height-big {
      height: 360px; } }

.PortfolioGrid-Sizer {
  width: 100%; }
  @media (min-width: 480px) {
    .PortfolioGrid-Sizer {
      width: 50%; } }
  @media (min-width: 768px) {
    .PortfolioGrid-Sizer {
      width: 33.33%; } }

.PortfolioCard {
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }
  .PortfolioCard-Hover {
    height: 100%;
    padding: 0 20px;
    background-color: rgba(77, 83, 87, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out 0.25s;
    opacity: 0;
    cursor: default; }
  .PortfolioCard-Type {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #fce47e;
    text-transform: uppercase;
    text-align: center; }
  .PortfolioCard-Title {
    margin-top: 5px;
    font-size: 24px;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center; }
  .PortfolioCard-Subtitle {
    margin-top: 5px;
    font-size: 16px;
    color: #ffffff;
    text-align: center; }
  .PortfolioCard-Btn {
    width: 163px;
    height: 45px;
    margin-top: 40px; }
  .PortfolioCard:hover .PortfolioCard-Hover {
    opacity: 1; }

.PortfolioNew-Body {
  padding-top: 25px;
  padding-bottom: 40px; }
  @media (min-width: 992px) {
    .PortfolioNew-Body {
      padding-top: 0;
      padding-bottom: 100px; } }
  @media (min-width: 1200px) {
    .PortfolioNew-Body {
      position: relative; } }

.PortfolioNew-Top {
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    .PortfolioNew-Top {
      display: flex;
      align-items: center; } }

.PortfolioNew-Title {
  margin-bottom: 10px; }
  @media (min-width: 768px) {
    .PortfolioNew-Title {
      margin-bottom: 0; } }

.PortfolioNew-Select {
  width: 160px;
  height: 27px; }
  @media (min-width: 768px) {
    .PortfolioNew-Select {
      margin-left: 35px; } }

.PortfolioNew-Content {
  width: calc(100% + 16px);
  left: -8px;
  position: relative; }
  .PortfolioNew-Content:after {
    content: "";
    display: table;
    clear: both; }
  @media (min-width: 768px) {
    .PortfolioNew-Content {
      width: calc(100% + 40px);
      left: -20px; } }

.PortfolioNew-MoreBtn {
  width: 227px;
  height: 54px;
  margin: 22px auto 0;
  font-weight: 500; }
  .PortfolioNew-MoreBtn-Arrow {
    width: 14px;
    height: 10px;
    margin-left: 16px;
    fill: #FFFFFF; }

.PortfolioNew-Egg {
  display: none; }
  @media (min-width: 1200px) {
    .PortfolioNew-Egg {
      position: absolute;
      display: block; } }
  .PortfolioNew-Egg_top {
    top: -210px;
    right: calc(100% + 177px); }
  .PortfolioNew-Egg_bot {
    left: calc(100% + 293px);
    bottom: -34px; }

.PortfolioNew .PortfolioNewGrid-Item {
  float: left;
  width: 100%;
  margin-bottom: 20px;
  padding: 8px; }
  @media (min-width: 480px) {
    .PortfolioNew .PortfolioNewGrid-Item {
      width: 50%; } }
  @media (min-width: 768px) {
    .PortfolioNew .PortfolioNewGrid-Item {
      margin-bottom: 40px;
      padding: 0 15px; } }
  @media (min-width: 992px) {
    .PortfolioNew .PortfolioNewGrid-Item {
      margin-bottom: 50px;
      padding: 0 22px; } }

.PortfolioNew .PortfolioNewGrid-Sizer {
  width: 100%; }
  @media (min-width: 480px) {
    .PortfolioNew .PortfolioNewGrid-Sizer {
      width: 50%; } }

.PortfolioNew-Show {
  display: flex;
  flex-wrap: wrap; }

.PortfolioNew .PortfolioNewCard {
  width: 100%;
  height: 100%; }
  .PortfolioNew .PortfolioNewCard:hover .PortfolioNewCard-Link {
    text-decoration: none; }
  .PortfolioNew .PortfolioNewCard-PreviewWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 25px 35px;
    background-color: #eef3f7;
    cursor: pointer; }
    @media (min-width: 992px) {
      .PortfolioNew .PortfolioNewCard-PreviewWrap {
        padding: 45px 64px; } }
  .PortfolioNew .PortfolioNewCard-Preview {
    max-width: 100%;
    display: block; }
  .PortfolioNew .PortfolioNewCard-Link {
    display: inline-block;
    margin-top: 5px;
    font-size: 14px;
    font-weight: 700;
    color: #6091f8;
    text-decoration: underline;
    cursor: pointer; }
    @media (min-width: 992px) {
      .PortfolioNew .PortfolioNewCard-Link {
        margin-top: 10px;
        font-size: 18px; } }

.About-Body {
  padding-top: 30px;
  padding-bottom: 17px;
  position: relative; }
  @media (min-width: 992px) {
    .About-Body {
      padding-top: 60px;
      padding-bottom: 50px; } }

.About-Content {
  position: relative;
  z-index: 3; }
  @media (min-width: 768px) {
    .About-Content {
      width: 70%;
      max-width: 870px; } }
  @media (min-width: 1200px) {
    .About-Content {
      width: 870px; } }

.About-Title {
  margin-bottom: 10px; }

.About-Logo {
  max-width: 100%;
  margin-bottom: 15px; }
  @media (min-width: 992px) {
    .About-Logo {
      margin-bottom: 30px; } }

.About-Description {
  margin-bottom: 35px;
  text-shadow: 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff; }
  @media (min-width: 768px) {
    .About-Description {
      max-width: 565px;
      margin-bottom: 50px; } }

.About-Info {
  width: 50%; }
  @media (min-width: 768px) {
    .About-Info {
      width: 100%; } }

@media (min-width: 768px) {
  .AboutInfo {
    display: flex; } }

.AboutInfo-Item {
  margin-bottom: 40px; }
  @media (min-width: 768px) {
    .AboutInfo-Item {
      width: 33.33%;
      margin: 0; } }
  .AboutInfo-Item:last-of-type {
    margin-bottom: 0; }

.AboutInfo-Num {
  margin-bottom: 5px;
  font-size: 60px;
  font-weight: 800;
  color: #fcb364;
  line-height: 0.8; }
  @media (min-width: 992px) {
    .AboutInfo-Num {
      margin-bottom: 0; } }

.AboutInfo-Text {
  line-height: 1.1; }
  @media (min-width: 992px) {
    .AboutInfo-Text {
      line-height: 1.3; } }
  .AboutInfo-Text br {
    display: none; }
    @media (min-width: 480px) {
      .AboutInfo-Text br {
        display: block; } }

.AboutDirector {
  position: absolute;
  right: 0;
  bottom: 59px;
  z-index: 2; }
  @media (min-width: 1200px) {
    .AboutDirector {
      right: 20px;
      bottom: 90px; } }
  .AboutDirector-Photo {
    position: absolute; }
    .AboutDirector-Photo_phone {
      right: -135px;
      bottom: -90px;
      z-index: -1;
      width: 391px; }
      @media (min-width: 992px) {
        .AboutDirector-Photo_phone {
          display: none; } }
    .AboutDirector-Photo_desktop {
      display: none; }
      @media (min-width: 992px) {
        .AboutDirector-Photo_desktop {
          right: -93px;
          bottom: -63px;
          display: block;
          width: 577px; } }
  .AboutDirector-Info {
    font-size: 14px;
    color: #ffffff;
    text-align: right;
    position: relative;
    z-index: 2; }
    @media (min-width: 992px) {
      .AboutDirector-Info {
        font-size: 18px; } }

.Feedback {
  overflow: hidden; }
  .Feedback-Body {
    padding-top: 17px;
    padding-bottom: 40px;
    position: relative; }
    @media (min-width: 1200px) {
      .Feedback-Body {
        padding-top: 30px;
        padding-bottom: 60px; } }
  .Feedback-Card {
    position: relative;
    z-index: 2; }
  .Feedback-Egg {
    position: absolute; }
    .Feedback-Egg_bot {
      width: 595px;
      left: calc(100% - 220px);
      bottom: -505px;
      transform: rotate(-30deg); }
      @media (min-width: 1200px) {
        .Feedback-Egg_bot {
          bottom: auto;
          top: calc(100% - 205px);
          left: calc(100% - 383px); } }

.FeedbackCard {
  padding: 25px 15px 20px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 13px 76px 0 rgba(119, 172, 248, 0.19); }
  @media (min-width: 992px) {
    .FeedbackCard {
      padding: 45px 70px 55px 60px; } }
  .FeedbackCard-Title {
    margin-bottom: 25px;
    font-size: 24px;
    font-weight: 900;
    text-align: center; }
    @media (min-width: 768px) {
      .FeedbackCard-Title {
        font-size: 36px;
        text-align: left; } }
    @media (min-width: 1200px) {
      .FeedbackCard-Title {
        margin-bottom: 40px; } }

.FeedbackSlider {
  padding-bottom: 80px;
  position: relative; }
  @media (min-width: 1200px) {
    .FeedbackSlider {
      padding-bottom: 130px; } }
  @media (min-width: 768px) {
    .FeedbackSlider-Slide {
      display: flex !important; } }
  .FeedbackSlider-Arrow {
    width: 48px;
    height: 48px;
    background-color: #78aef8;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    transition: all ease-in-out 0.25s;
    cursor: pointer; }
    @media (min-width: 768px) {
      .FeedbackSlider-Arrow {
        width: 62px;
        height: 62px; } }
    .FeedbackSlider-Arrow:hover {
      background-color: #6091f8; }
    .FeedbackSlider-Arrow-Img {
      display: block;
      width: 12px;
      height: 20px;
      fill: #FFFFFF; }
    .FeedbackSlider-Arrow_prev {
      right: calc(50% + 4px); }
      @media (min-width: 768px) {
        .FeedbackSlider-Arrow_prev {
          right: 73px; } }
    .FeedbackSlider-Arrow_next {
      left: calc(50% + 4px);
      transform: rotate(-180deg); }
      @media (min-width: 768px) {
        .FeedbackSlider-Arrow_next {
          left: auto;
          right: 0; } }

.FeedbackSlide-AvatarWrap {
  width: 134px;
  height: 134px;
  margin: 0 auto 15px;
  background-color: #f1f8fe;
  border-radius: 50%;
  overflow: hidden; }

.FeedbackSlide-Avatar {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  display: block; }

@media (min-width: 768px) {
  .FeedbackSlide-Content {
    width: calc(100% - 134px);
    padding-left: 34px; } }

.FeedbackSlide-Name {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
  color: #6091f8;
  text-align: center; }
  @media (min-width: 768px) {
    .FeedbackSlide-Name {
      margin-bottom: 0;
      font-size: 18px;
      text-align: left; } }

.FeedbackSlide-Company {
  margin-bottom: 15px;
  font-size: 14px;
  text-align: center; }
  @media (min-width: 768px) {
    .FeedbackSlide-Company {
      margin-bottom: 25px;
      font-size: 18px;
      text-align: left; } }
  @media (min-width: 768px) {
    .FeedbackSlide-Company br {
      display: none; } }

.FeedbackSlide-Text {
  padding: 0 30px;
  font-size: 16px;
  text-align: justify;
  position: relative; }
  @media (min-width: 768px) {
    .FeedbackSlide-Text {
      padding: 0 25px;
      font-size: 18px;
      text-align: left; } }
  .FeedbackSlide-Text:before, .FeedbackSlide-Text:after {
    content: '“';
    font-size: 72px;
    font-weight: 100;
    color: #6091f8;
    line-height: 0.8;
    position: absolute; }
  .FeedbackSlide-Text:before {
    top: 0;
    left: -0; }
  .FeedbackSlide-Text:after {
    content: '”';
    line-height: 0;
    right: 0;
    bottom: -10px; }
    @media (min-width: 768px) {
      .FeedbackSlide-Text:after {
        display: inline-block;
        position: relative;
        bottom: -33px; } }

.Offer {
  background-color: #f1f8fe;
  overflow: hidden; }
  .Offer-Body {
    padding-top: 30px;
    position: relative; }
    @media (min-width: 768px) {
      .Offer-Body {
        padding-bottom: 80px; } }
    @media (min-width: 992px) {
      .Offer-Body {
        padding-top: 70px;
        padding-bottom: 100px; } }
  .Offer-Content {
    position: relative;
    z-index: 2; }
    @media (min-width: 768px) {
      .Offer-Content {
        display: flex;
        align-items: flex-end; } }
  .Offer-Left {
    margin-bottom: 30px; }
    @media (min-width: 768px) {
      .Offer-Left {
        width: 65%;
        max-width: 520px;
        margin-bottom: 0; } }
    @media (min-width: 992px) {
      .Offer-Left {
        width: 520px; } }
  .Offer-Title {
    margin-bottom: 30px;
    font-size: 34px;
    font-weight: 900;
    line-height: 1; }
    @media (min-width: 992px) {
      .Offer-Title {
        font-size: 48px; } }
    @media (min-width: 1200px) {
      .Offer-Title {
        margin-bottom: 30px; } }
  .Offer-Text {
    margin-bottom: 25px;
    font-size: 16px;
    color: #9da5aa;
    line-height: 1.5; }
    @media (min-width: 992px) {
      .Offer-Text {
        font-size: 18px; } }
    @media (min-width: 1200px) {
      .Offer-Text {
        margin-bottom: 35px; } }
    .Offer-Text span {
      padding: 0 4px;
      background-color: #ffffff; }
  @media (min-width: 768px) {
    .Offer-Right {
      width: 35%;
      margin-bottom: -80px; } }
  @media (min-width: 992px) {
    .Offer-Right {
      width: calc(100% - 520px);
      margin-bottom: -100px; } }
  .Offer-ImgHand {
    max-width: calc(100% + 50px);
    display: block;
    position: relative;
    left: -25px; }
    @media (min-width: 768px) {
      .Offer-ImgHand {
        max-width: 100%;
        left: auto; } }
    @media (min-width: 1200px) {
      .Offer-ImgHand {
        width: 724px;
        max-width: none; } }
  .Offer-Egg {
    position: absolute; }
    .Offer-Egg_left {
      top: calc(100% - 225px);
      right: calc(100% - 162px); }
      @media (min-width: 768px) {
        .Offer-Egg_left {
          top: calc(100% - 120px); } }
      @media (min-width: 1200px) {
        .Offer-Egg_left {
          top: calc(100% - 250px);
          right: calc(100% + 150px); } }
    .Offer-Egg_right {
      left: calc(100% - 76px);
      bottom: calc(100% - 311px); }
      @media (min-width: 1200px) {
        .Offer-Egg_right {
          left: calc(100% - 250px);
          bottom: 15px; } }

.Contact {
  background-color: #f1f8fe;
  overflow: hidden; }
  .Contact-Body {
    padding-top: 70px;
    padding-bottom: 50px;
    position: relative; }
    @media (min-width: 575px) {
      .Contact-Body {
        padding-top: 80px;
        padding-bottom: 100px; } }
  .Contact-Simg {
    width: 350px;
    position: absolute;
    top: 17px;
    right: -80px; }
    @media (min-width: 575px) {
      .Contact-Simg {
        width: auto; } }
    @media (min-width: 1200px) {
      .Contact-Simg {
        top: 0;
        right: -147px; } }
  .Contact-Egg {
    position: absolute; }
    .Contact-Egg_left-bot {
      display: none; }
      @media (min-width: 1200px) {
        .Contact-Egg_left-bot {
          top: calc(100% - 106px);
          right: calc(100% + 177px);
          display: block; } }

.ContactCard {
  position: relative;
  z-index: 2; }
  .ContactCard-Top {
    margin-bottom: 30px;
    position: relative; }
    @media (min-width: 575px) {
      .ContactCard-Top {
        margin-bottom: 50px; } }
    .ContactCard-Top-Egg {
      width: 377px;
      position: absolute;
      top: -35px;
      left: -40px;
      z-index: -1; }
      @media (min-width: 575px) {
        .ContactCard-Top-Egg {
          width: auto;
          top: -45px;
          left: -70px; } }
  .ContactCard-Title {
    margin-bottom: 15px;
    font-size: 24px; }
    @media (min-width: 575px) {
      .ContactCard-Title {
        margin-bottom: 40px;
        font-size: 36px; } }
  .ContactCard-Form {
    padding: 20px 20px 40px;
    background-color: #6091f8; }
    @media (min-width: 575px) {
      .ContactCard-Form {
        padding: 30px 35px 55px; } }

.ContactInfo-Item {
  position: relative;
  margin-bottom: 10px;
  padding-left: 55px;
  font-size: 18px;
  color: inherit;
  text-decoration: none; }
  @media (min-width: 575px) {
    .ContactInfo-Item {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      font-size: 24px; } }
  .ContactInfo-Item:last-child {
    margin-bottom: 0; }
  .ContactInfo-Item-Phone {
    font-size: 24px;
    font-weight: 700;
    color: #6091f8;
    text-decoration: none; }
    @media (min-width: 575px) {
      .ContactInfo-Item-Phone {
        font-size: 30px; } }

.ContactInfo-IconWrap {
  position: absolute;
  top: -6px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 39px;
  height: 39px;
  background-color: #6091f8;
  border-radius: 50%; }
  @media (min-width: 575px) {
    .ContactInfo-IconWrap {
      top: -3px; } }

.ContactInfo-Icon {
  fill: #FFFFFF; }
  .ContactInfo-Icon_phone {
    width: 20px;
    height: 20px; }
  .ContactInfo-Icon_mail {
    width: 20px;
    height: 14px; }

.ContactInfo-AppLinks {
  display: block;
  padding-top: 10px; }
  @media (min-width: 575px) {
    .ContactInfo-AppLinks {
      margin-left: 50px;
      padding-top: 0; } }

.ContactInfo-Item:nth-of-type(1) .ContactInfo-Icon {
  top: 5px; }

@media (min-width: 575px) {
  .ContactInfo-Item:nth-of-type(2) .ContactInfo-Icon {
    top: 0; } }

.ContactAppLinks {
  display: flex; }
  .ContactAppLinks-Item {
    display: block;
    width: 41px;
    height: 41px;
    margin-right: 10px; }
    .ContactAppLinks-Item:last-child {
      margin-right: 0; }
  .ContactAppLinks img {
    display: block;
    width: 100%;
    height: 100%; }

.ContactForm {
  position: relative; }
  .ContactForm-Title {
    margin-bottom: 8px;
    font-size: 24px;
    font-weight: 800;
    color: #ffffff;
    line-height: 1; }
    @media (min-width: 575px) {
      .ContactForm-Title {
        margin-bottom: 15px;
        font-size: 30px; } }
    @media (min-width: 768px) {
      .ContactForm-Title {
        font-size: 36px; } }
    @media (min-width: 575px) {
      .ContactForm-Title br {
        display: none; } }
  .ContactForm-Subtitle {
    max-width: 270px;
    margin-bottom: 15px;
    font-size: 14px;
    color: #ffffff;
    line-height: 1.2;
    letter-spacing: 0.2px; }
    @media (min-width: 575px) {
      .ContactForm-Subtitle {
        max-width: 340px;
        margin-bottom: 28px;
        font-size: 18px;
        letter-spacing: 0.1px; } }
    @media (min-width: 768px) {
      .ContactForm-Subtitle {
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0.3px; } }
  .ContactForm-LogoBg {
    display: none; }
    @media (min-width: 768px) {
      .ContactForm-LogoBg {
        position: absolute;
        left: 0;
        bottom: -25px;
        display: block; } }
  .ContactForm-SpecImg {
    display: none; }
    @media (min-width: 992px) {
      .ContactForm-SpecImg {
        position: absolute;
        top: -110px;
        right: -20px;
        display: block; } }
  .ContactForm-Form {
    position: relative;
    z-index: 2; }

.ExampleIntro-Body {
  padding-top: 25px;
  padding-bottom: 18px; }
  @media (min-width: 768px) {
    .ExampleIntro-Body {
      padding-top: 50px;
      padding-bottom: 33px; } }

.ExampleIntro-Title {
  margin-bottom: 15px; }
  @media (min-width: 768px) {
    .ExampleIntro-Title {
      margin-bottom: 25px; } }

.ExampleIntro-Content {
  margin-top: 40px; }
  @media (min-width: 575px) {
    .ExampleIntro-Content {
      display: flex;
      align-items: flex-start;
      justify-content: space-between; } }
  @media (min-width: 768px) {
    .ExampleIntro-Content {
      margin-top: 80px; } }

.ExampleIntro-Left {
  max-width: 310px;
  margin: 0 auto 37px;
  position: relative; }
  @media (min-width: 575px) {
    .ExampleIntro-Left {
      width: 70%;
      max-width: none;
      margin: 0;
      padding-right: 5%; } }
  @media (min-width: 992px) {
    .ExampleIntro-Left {
      width: 65%;
      padding: 0; } }
  .ExampleIntro-Left-Egg {
    width: 285px;
    position: absolute;
    top: -16px;
    right: -64px;
    z-index: -1; }
    @media (min-width: 768px) {
      .ExampleIntro-Left-Egg {
        width: 400px;
        top: -35px;
        right: auto;
        left: 165px; } }
    @media (min-width: 992px) {
      .ExampleIntro-Left-Egg {
        width: 585px; } }

.ExampleIntro-Right {
  width: calc(100% - 60px);
  max-width: 250px;
  margin: 0 auto;
  padding: 25px 40px 55px;
  background-color: #f1f8fe;
  position: relative; }
  @media (min-width: 575px) {
    .ExampleIntro-Right {
      width: 30%;
      max-width: none;
      margin: 0; } }
  @media (min-width: 992px) {
    .ExampleIntro-Right {
      width: 35%;
      max-width: 360px;
      padding: 40px 76px 90px; } }
  .ExampleIntro-Right-Simg {
    position: absolute;
    z-index: -1; }
    .ExampleIntro-Right-Simg_bashgos {
      width: 298px;
      left: -83px;
      bottom: 30px; }
      @media (min-width: 992px) {
        .ExampleIntro-Right-Simg_bashgos {
          width: 494px;
          left: -137px;
          bottom: 50px; } }

.ExampleIntroDesktop {
  width: 100%;
  max-width: 615px;
  padding: 4% 3% 27%;
  background-image: url(/img/ExampleIntroDesktop.png);
  background-repeat: no-repeat;
  background-size: 100% auto; }
  .ExampleIntroDesktop-Img {
    max-width: 100%;
    display: block; }

.ExampleIntroMobile {
  width: 100%;
  padding: 23% 6% 26%;
  background-image: url(/img/ExampleIntroMobile.png);
  background-repeat: no-repeat;
  background-size: 100% auto; }
  .ExampleIntroMobile-Img {
    max-width: 100%;
    margin-top: 1px;
    display: block; }

.ExampleResults-Body {
  padding-top: 18px;
  padding-bottom: 20px; }
  @media (min-width: 1200px) {
    .ExampleResults-Body {
      padding-top: 33px;
      padding-bottom: 45px;
      position: relative; } }

.ExampleResults-Egg {
  display: none; }
  @media (min-width: 1200px) {
    .ExampleResults-Egg {
      position: absolute;
      display: block; } }
  @media (min-width: 1200px) {
    .ExampleResults-Egg_left {
      top: -35px;
      right: calc(100% + 250px); } }

.ExampleResultsCard {
  padding: 15px 20px 30px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 13px 76px 0 rgba(119, 172, 248, 0.19); }
  @media (min-width: 992px) {
    .ExampleResultsCard {
      padding: 40px 0 55px 35px; } }
  @media (min-width: 768px) {
    .ExampleResultsCard-Info {
      display: flex; } }
  @media (min-width: 1200px) {
    .ExampleResultsCard-Info {
      padding: 0 80px 0 15px; } }
  .ExampleResultsCard-Info-Item {
    margin-bottom: 25px;
    padding-bottom: 20px;
    border-bottom: 3px solid #f1f8fe; }
    @media (min-width: 768px) {
      .ExampleResultsCard-Info-Item {
        width: 50%;
        padding-right: 15px;
        border-bottom: none;
        border-right: 3px solid #f1f8fe; } }
    @media (min-width: 1200px) {
      .ExampleResultsCard-Info-Item {
        padding-right: 80px; } }
    .ExampleResultsCard-Info-Item:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
      border: none; }
      @media (min-width: 768px) {
        .ExampleResultsCard-Info-Item:last-of-type {
          padding-right: 0;
          padding-left: 15px; } }
      @media (min-width: 1200px) {
        .ExampleResultsCard-Info-Item:last-of-type {
          padding-left: 50px; } }
  .ExampleResultsCard-Info-Title {
    margin-bottom: 5px;
    font-size: 24px;
    font-weight: 800;
    color: #6091f8;
    text-transform: uppercase; }
    @media (min-width: 768px) {
      .ExampleResultsCard-Info-Title {
        font-size: 36px; } }
  .ExampleResultsCard-Numbers {
    margin-top: 30px; }
    @media (min-width: 1200px) {
      .ExampleResultsCard-Numbers {
        margin-top: 70px; } }
    .ExampleResultsCard-Numbers-Title {
      margin-bottom: 13px;
      font-size: 18px;
      font-weight: 700;
      color: #6091f8; }
      @media (min-width: 768px) {
        .ExampleResultsCard-Numbers-Title {
          margin-bottom: 28px;
          font-size: 24px; } }
    @media (min-width: 575px) {
      .ExampleResultsCard-Numbers-Content {
        display: flex;
        flex-wrap: wrap; } }
    @media (min-width: 768px) {
      .ExampleResultsCard-Numbers-Content {
        flex-wrap: nowrap; } }
    .ExampleResultsCard-Numbers-Item {
      margin-bottom: 30px; }
      @media (min-width: 575px) {
        .ExampleResultsCard-Numbers-Item {
          width: 50%; } }
      @media (min-width: 768px) {
        .ExampleResultsCard-Numbers-Item {
          width: 50%;
          margin-bottom: 0;
          padding-right: 2%; } }
      @media (min-width: 992px) {
        .ExampleResultsCard-Numbers-Item {
          width: 36%;
          padding-right: 0; } }
      @media (min-width: 992px) {
        .ExampleResultsCard-Numbers-Item:first-of-type {
          width: 28%; } }
      .ExampleResultsCard-Numbers-Item:last-of-type {
        margin-bottom: 0; }
    .ExampleResultsCard-Numbers-Num {
      font-size: 48px;
      font-weight: 700;
      color: #fcb364;
      white-space: nowrap; }
      @media (min-width: 992px) {
        .ExampleResultsCard-Numbers-Num {
          font-size: 60px; } }

.ExampleBRoll-Body {
  padding-top: 20px;
  padding-bottom: 40px; }
  @media (min-width: 1200px) {
    .ExampleBRoll-Body {
      padding-top: 45px;
      padding-bottom: 80px;
      position: relative; } }

.ExampleBRoll-Content {
  display: flex;
  justify-content: space-between; }

.ExampleBRoll-Item {
  width: calc(33.33% - 6px * 2/3); }
  @media (min-width: 992px) {
    .ExampleBRoll-Item {
      width: calc(33.33% - 17px * 2/3); } }

.ExampleBRoll-Btn {
  max-width: 230px;
  margin: 30px auto 0; }
  @media (min-width: 992px) {
    .ExampleBRoll-Btn {
      margin-top: 70px; } }

.ExampleBRoll-Egg {
  display: none; }
  @media (min-width: 1200px) {
    .ExampleBRoll-Egg {
      position: absolute;
      display: block; } }
  @media (min-width: 1200px) {
    .ExampleBRoll-Egg_right {
      top: 184px;
      left: calc(100% + 310px); } }

.ExampleBRollCard {
  background-color: #fff;
  box-shadow: 0px 13px 101px 0 rgba(104, 153, 248, 0.36); }
  .ExampleBRollCard-Img {
    max-width: 100%;
    display: block; }

.ImPoliticWrap {
  max-width: 700px;
  font-weight: 300; }
  .ImPoliticWrap-Title {
    font-size: 20px;
    font-weight: bold; }

.ImCallback {
  width: auto;
  max-width: 320px;
  padding: 20px 10px; }
  @media (min-width: 480px) {
    .ImCallback {
      width: 320px;
      max-width: none;
      padding: 30px 20px 20px; } }
  .ImCallback-Title {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: bold;
    color: #6091f8;
    text-align: center;
    text-transform: uppercase; }
  .ImCallback-Subtitle {
    margin-bottom: 20px;
    font-size: 16px;
    color: #444444;
    text-align: center; }
  .ImCallback-Input {
    margin-bottom: 10px; }
  .ImCallback-Btn {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px; }
    @media (min-width: 480px) {
      .ImCallback-Btn {
        padding-left: inherit;
        padding-right: inherit; } }

.ImThanks {
  display: none; }
  .ImThanks-Title {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: bold;
    color: #6091f8;
    text-align: center;
    text-transform: uppercase; }
  .ImThanks-Subtitle {
    margin-bottom: 20px;
    font-size: 16px;
    color: #444444;
    text-align: center; }
  .ImThanks-Btn {
    width: 100%; }

.Footer {
  padding: 17px 0 16px;
  background-color: #333333; }
  .Footer-Body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column; }
    @media (min-width: 480px) {
      .Footer-Body {
        flex-direction: row; } }
  .Footer-Politic {
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 0.5px; }
  .Footer-FijiLogo {
    width: 205px; }
    .Footer-FijiLogo img {
      max-width: 100%;
      height: auto;
      display: inline-block; }
