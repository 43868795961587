.ImThanks {
  display: none;
  &-Title {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: bold;
    color: $main-color;
    text-align: center;
    text-transform: uppercase; }
  &-Subtitle {
    margin-bottom: 20px;
    font-size: 16px;
    color: #444444;
    text-align: center; }
  &-Btn {
    width: 100%; } }
