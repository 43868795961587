
$font-default: Arial, Helvetica, sans-serif;
$webfont-default: 'ProximaNova', sans-serif;
$main-color: #6091f8;
$accent-color: #fcb364;

$main-gradient-start: #6091f8;
$main-gradient-end: #78aef8;
$accent-gradient-start: #fcb364;
$accent-gradient-end: #fce57f;

$transition-base: all ease-in-out .25s;
