.Header {
  width: 100%;
  padding: 11px 0;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.18);
  @include mf(sm) {
    padding: 0;
    background-color: transparent;
    position: absolute;
    box-shadow: none; }
  .Container {
    padding: 0;
    @include mf(sm) {
      padding: 11px 15px 9px; }
    @include mf(lg) {
      padding-top: 34px; } }
  &-Body {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  &-Logo {
    width: 157px; }
  &-Left {
    display: flex;
    align-items: center; }
  &-Right {
    display: flex;
    align-items: center; }
  &-Nav {
    display: none;
    @include mf(sm) {
      margin-left: 30px;
      display: flex; } }
  &-CallBackBtn {
    display: none;
    @include mf(sm) {
      width: 166px;
      height: 39px;
      font-size: 12px;
      border-radius: 19.5px;
      display: flex; } } }

.HeaderInfo {
  display: flex;
  flex-direction: column;
  &-Phone {}
  &-Text {
    display: none;
    @include mf(xs) {
      display: initial; } } }

.HeaderPhone {
  width: 49px;
  height: 45px;
  padding: 16px 14px;
  background: $main-gradient-start;
  background: linear-gradient(to right, $main-gradient-start 0%,$main-gradient-end 100%);
  border-radius: 10px 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include mf(sm) {
    width: initial;
    height: initial;
    padding: initial;
    color: #000;
    background: initial;
    border: none;
    text-decoration: none;
    display: initial; }
  @include mf(sl) {
    margin-left: 25px;
    display: flex; }
  &-Icon {
    display: none;
    @include mf(sl) {
      width: 16px;
      height: 16px;
      margin-right: 5px;
      fill: #FFFFFF;
      display: block; } }
  &-Number {
    display: none;
    @include mf(sl) {
      font-size: 18px;
      font-weight: 300;
      color: #ffffff;
      display: initial; } }
  &-Img {
    width: 32px;
    display: block;
    @include mf(sm) {
      display: none; } } }

.HeaderNav {
  display: none;
  @include mf(sm) {
    display: flex;
    align-items: center; }
  &-Link {
    margin-right: 20px;
    font-size: 14px;
    font-weight: 800;
    color: #b0b9c0;
    text-decoration: none;
    text-transform: uppercase;
    transition: $transition-base;
    &:last-of-type {
      margin-right: 0; }
    &:hover,
    &.active {
      color: #4d5357; } } }

.DefmenuBtn {
  background: $main-gradient-start;
  background: linear-gradient(to right, $main-gradient-start 0%,$main-gradient-end 100%);
  display: flex;
  @include mf(sm) {
    display: none; } }

