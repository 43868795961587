.ExampleResults {
  &-Body {
    padding-top: 18px;
    padding-bottom: 20px;
    @include mf(lg) {
      padding-top: 33px;
      padding-bottom: 45px;
      position: relative; } }
  &-Egg {
    display: none;
    @include mf(lg) {
      position: absolute;
      display: block; }
    &_left {
      @include mf(lg) {
        top: -35px;
        right: calc(100% + 250px); } } } }

.ExampleResultsCard {
  padding: 15px 20px 30px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 13px 76px 0 rgba(119, 172, 248, 0.19);
  @include mf(sl) {
    padding: 40px 0 55px 35px; }
  &-Info {
    @include mf(sm) {
      display: flex; }
    @include mf(lg) {
      padding: 0 80px 0 15px; }
    &-Item {
      margin-bottom: 25px;
      padding-bottom: 20px;
      border-bottom: 3px solid #f1f8fe;
      @include mf(sm) {
        width: 50%;
        padding-right: 15px;
        border-bottom: none;
        border-right: 3px solid #f1f8fe; }
      @include mf(lg) {
        padding-right: 80px; }
      &:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0;
        border: none;
        @include mf(sm) {
          padding-right: 0;
          padding-left: 15px; }
        @include mf(lg) {
          padding-left: 50px; } } }
    &-Title {
      margin-bottom: 5px;
      font-size: 24px;
      font-weight: 800;
      color: $main-color;
      text-transform: uppercase;
      @include mf(sm) {
        font-size: 36px; } }
    &-Text {} }
  &-Numbers {
    margin-top: 30px;
    @include mf(lg) {
      margin-top: 70px; }
    &-Title {
      margin-bottom: 13px;
      font-size: 18px;
      font-weight: 700;
      color: $main-color;
      @include mf(sm) {
        margin-bottom: 28px;
        font-size: 24px; } }
    &-Content {
      @include mf(sx) {
        display: flex;
        flex-wrap: wrap; }
      @include mf(sm) {
        flex-wrap: nowrap; } }
    &-Item {
      margin-bottom: 30px;
      @include mf(sx) {
        width: 50%; }
      @include mf(sm) {
        width: 50%;
        margin-bottom: 0;
        padding-right: 2%; }
      @include mf(sl) {
        width: 36%;
        padding-right: 0; }
      &:first-of-type {
        @include mf(sl) {
          width: 28%; } }
      &:last-of-type {
        margin-bottom: 0; } }
    &-Num {
      font-size: 48px;
      font-weight: 700;
      color: $accent-color;
      white-space: nowrap;
      @include mf(sl) {
        font-size: 60px; } }
    &-Text {} } }
